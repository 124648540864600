import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: "de",
  messages: loadLocaleMessages(),
});

Vue.filter("formatCurrency", (value: number) => {
  return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(value);
});

Vue.filter("formatNumber", (value: number, maximumFractionDigits: number = 2) => {
  return new Intl.NumberFormat("de-DE", { maximumFractionDigits }).format(value);
});
