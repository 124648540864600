


























































import Vue from "vue";
import { LatLng, latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LControlLayers } from "vue2-leaflet";
import LNonTiledLayerWMS from "@/components/leaflet/LNonTiledLayerWMS.vue";

import { Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LControlLayers,
    "l-wms-tile-layer": LNonTiledLayerWMS,
  },
})
export default class WmaPrices extends Vue {
  activeLayerName: string | null = null;
  readonly defaultLayerName = "pricelayer_hk";
  readonly defaultMap = "iibmap";

  defaultPosition: LatLng = latLng(51.133481, 10.018343);
  defaultZoom = 8;
  wmsLayerBaseUrl = "https://map.iib-institut.de/atlas/service";
  showLegend = false;

  tileLayers = [
    {
      name: "iibmap",
      url: "https://map.iib-institut.de/iibinstitut/wmts/iibmap/GLOBAL_WEBMERCATOR/{z}/{x}/{y}.png",
      attribution: `Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a> | &copy; <a href="http://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors / Lizenz: <a href="http://opendatacommons.org/licenses/odbl/" target="_blank">ODbL</a>`,
    },
    {
      name: "iibsw",
      layers: "iibsw",
      url: "https://map.iib-institut.de/iibinstitut/wmts/iibsw/GLOBAL_WEBMERCATOR/{z}/{x}/{y}.png",
      attribution: `Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a> | &copy; <a href="http://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors / Lizenz: <a href="http://opendatacommons.org/licenses/odbl/" target="_blank">ODbL</a>`,
    },
    {
      name: "osm",
      layers: "osm",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
  ];
  wmsLayers = [
    {
      name: "pricelayer_hk",
      layers: "pricelayer_hk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wk",
      layers: "pricelayer_wk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_hm",
      layers: "pricelayer_hm",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wm",
      layers: "pricelayer_wm",
      format: "image/png",
      transparent: true,
    },
  ];

  mapOptions = {
    zoomSnap: 0.5,
  };

  get position(): LatLng | null {
    const search = new URLSearchParams(window.location.search);
    if (search.has("pos")) {
      const latLngS = search.get("pos")?.split(",");
      return latLng(parseFloat(latLngS![0]), parseFloat(latLngS![1]));
    }
    return null;
  }

  get zoom(): number | null {
    const search = new URLSearchParams(window.location.search);
    if (search.has("z")) {
      return parseFloat(search.get("z")!);
    }
    return null;
  }

  get map(): string {
    const search = new URLSearchParams(window.location.search);
    if (search.has("m")) {
      return search.get("m")!;
    }
    return this.defaultMap;
  }

  get activeLayer(): string {
    const search = new URLSearchParams(window.location.search);
    if (search.has("layer") && this.activeLayerName === null) {
      return `pricelayer_${search.get("layer")!}`;
    }
    return this.activeLayerName || this.defaultLayerName;
  }

  set activeLayer(val: string) {
    const search = new URLSearchParams(window.location.search);
    search.set("layer", val.substring(val.indexOf("_") + 1));
    window.history.replaceState({}, "", `${location.pathname}?${search}`);
    this.activeLayerName = val;
  }
}
