






import Vue from "vue";
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: "primary",
    },

    height: {
      type: String,
      default: "6px",
    },
  },
});
