import Vue from "vue";
import Vuex, { createNamespacedHelpers } from "vuex";
import { namespace, Getter } from "vuex-class";
import { set } from "./helpers";
import VuexPersistence from "vuex-persist";
Vue.use(Vuex);

export enum Actions {}

export enum Mutations {
  setSnackbar = "setSnackbar",
  setDrawer = "setDrawer",
}

export enum States {
  drawer = "drawer",
  snackbar = "snackbar",
}

const vuexPersist = new VuexPersistence({});

export const wma = namespace("wma");

const store = new Vuex.Store({
  state: { snackbar: {}, drawer: true },
  plugins: [vuexPersist.plugin],
  mutations: {
    setSnackbar: set("snackbar"),
    setDrawer: set("drawer"),
  },
  actions: {},

  modules: {},
});

export default store;
