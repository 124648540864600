import Vue from "vue";
import App from "@/App.vue";
import "@/components/base";
import store from "./store";
import "./plugins/base";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";

// @ts-ignore
import "leaflet/dist/leaflet.css";
import L from "leaflet";

type D = L.Icon.Default & {
  _getIconUrl?: string;
};

delete (L.Icon.Default.prototype as D)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

Vue.config.productionTip = false;

Vue.use(require("vue-moment"));

new Vue({
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
