<template>
  <v-row :justify="justify">
    <v-col v-for="(item, i) in values" :key="i" class="verification-field--col">
      <v-text-field
        :key="i"
        outlined
        :ref="i"
        :class="{
          input: true,
          first: i === 0,
          last: i === length - 1
        }"
        @paste="pasteDate($event, i)"
        @input="$emit('input', values.join(''))"
        @keydown="keyDown($event, i)"
        v-model="values[i]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};
import Vue from "vue";
export default Vue.extend({
  props: {
    length: {
      type: [String, Number]
    },
    justify: {
      type: String
    }
  },

  created() {
    for (let i = 0; i < this.length; ++i) {
      this.values.push("");
    }
  },
  data: () => ({
    values: []
  }),
  methods: {
    async keyDown($event, index) {
      if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) {
        return;
      }
      if ($event.keyCode === KEY_CODE.backspace || $event.keyCode === KEY_CODE.left) {
        if (index > 0) {
          setTimeout(() => {
            this.$refs[index - 1][0].$refs.input.focus();
          }, 20);
        }
      } else {
        if (this.$refs[index][0].$refs.input.value.length > 0) {
          this.values[index] = "";
          this.$forceUpdate();
        }
        if (index < this.length - 1) {
          setTimeout(() => {
            this.$refs[index + 1][0].$refs.input.focus();
          }, 20);
        }
      }
    },
    async pasteDate($event, index) {
      const clipboardData = $event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text/plain").trim();
      $event.preventDefault();
      for (let i = 0; i < this.length; ++i) {
        this.values[i] = pastedData.charAt(i);
      }
      this.$forceUpdate();
      this.$emit("input", this.values.join(""));
    }
  }
});
</script>

<style lang="scss">
.verification-field {
  &--col {
    max-width: 58px;
    padding: 12px 0 12px 0;
    .input {
      border-radius: 0px;
      &.first {
        border-radius: 8px 0 0 8px;
        padding: unset;
      }
      &.last {
        border-radius: 0 8px 8px 0;
      }
      input {
        text-align: center;
      }
    }
  }
}
</style>
