import Vue from "vue";
import Vuetify from "vuetify/lib";
import VuetifyConfirm from "vuetify-confirm";

Vue.use(Vuetify);
const vuetify = new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: "#F00",
        // secondary: "#D9D9D9",
        // secondary: "#FFF",
        // accent: "#FFF",
        // error: "#FF0000",
        // info: "#2196F3",
        // success: "#4CAF50",
        // warning: "#FFC107",
        // background: "#F00",
      },
    },
  },
});

Vue.use(VuetifyConfirm, { vuetify });

export default vuetify;
