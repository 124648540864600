var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-img',{attrs:{"height":"100%","width":"100%"}},[_c('v-select',{staticClass:"layer-selection",attrs:{"items":_vm.wmsLayers,"solo":"","hide-details":"","item-value":"name"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("map.layer." + (item.name))))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("map.layer." + (item.name))))+" ")]}}]),model:{value:(_vm.activeLayer),callback:function ($$v) {_vm.activeLayer=$$v},expression:"activeLayer"}}),_c('v-bottom-sheet',{attrs:{"hide-overlay":"","inset":"","persistent":"","no-click-animation":"","content-class":"legend--bottomsheet"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"legend"},'v-btn',attrs,false),on),[_vm._v(" Legende "+_vm._s(_vm.showLegend ? "ausblenden" : "anzeigen")+" ")])]}}]),model:{value:(_vm.showLegend),callback:function ($$v) {_vm.showLegend=$$v},expression:"showLegend"}},[_c('v-sheet',{staticStyle:{"background-color":"#FFF0"},attrs:{"height":"91"}},[_c('img',{staticStyle:{"object-fit":"contain","width":"100%"},attrs:{"height":"85px","src":require('@/assets/lagebewertung.jpg')}})])],1),_c('l-map',{ref:"map",attrs:{"zoom":_vm.zoom || _vm.defaultZoom,"center":_vm.position || _vm.defaultPosition,"options":_vm.mapOptions}},[_vm._l((_vm.tileLayers),function(tileLayer){return _c('l-tile-layer',{key:tileLayer.name,attrs:{"url":tileLayer.url,"layers":tileLayer.layers,"name":_vm.$t(("map.layer." + (tileLayer.name))),"attribution":tileLayer.attribution,"visible":tileLayer.name === _vm.map,"layer-type":"base"}})}),_vm._l((_vm.wmsLayers),function(wmsLayer){return [(wmsLayer.name === _vm.activeLayer)?_c('l-wms-tile-layer',{key:wmsLayer.name,attrs:{"base-url":_vm.wmsLayerBaseUrl,"layers":wmsLayer.layers,"name":_vm.$t(("map.layer." + (wmsLayer.name))),"format":wmsLayer.format,"transparent":wmsLayer.transparent,"layer-type":"overlay"}}):_vm._e()]}),(_vm.position)?_c('l-marker',{attrs:{"lat-lng":_vm.position}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }