






import Vue from "vue";
import Component from "vue-class-component";
import Layout from "@/components/core/Layout.vue";

import MapC from "@/components/Map.vue";

@Component({
  components: { MapC, Layout },
})
export default class Wma extends Vue {}
