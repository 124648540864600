<template>
  <div class="mb-4" v-bind="$attrs" v-on="$listeners">
    <h1 class="heading-3" v-text="text" />
    <div class="subheading" v-text="sub" />
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,

  props: {
    sub: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
  },
});
</script>
